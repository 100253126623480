const scrollTo = (element, duration) => {
    const scrollAnimation = () => import('./animations/_scrollTo')

    scrollAnimation().then((module) => {
        module.scrollTo(element, duration)
    })
}

const revealElementsOnScroll = () => {
    const elements = document.querySelectorAll('.reveal')

    if (elements.length > 0) {
        const revealOnScrollAnimation = () => import('./animations/_reveal-on.scroll')

        revealOnScrollAnimation().then((module) => {
            module.reveal(elements)
        })
    }
}

const changeSiteGradient = () => {
    const buttons = document.querySelectorAll('.gradient-selector button')

    if (buttons.length) {
        const gradientAnimation = () => import('./animations/_change-site-gradient')

        for (const button of buttons) {
            button.addEventListener('click', (event) => {
                gradientAnimation().then((module) => {
                    module.replaceGradient(event)
                })
            })
        }
    }
}

const headerSubMenu = () => {
    const
        subMenus = document.querySelectorAll('.top-navigation__desktop .menu-item-has-children'),
        menuAnimation = () => import('./animations/_header-desktop-submenu')

    if (subMenus.length > 0) {
        for (const menu of subMenus) {
            const
                trigger = menu.querySelector('* > a'),
                subMenu = menu.querySelector('.sub-menu')

            let delay = 100,
                setTimeoutConst;

            trigger.addEventListener('click', (event) => {
                event.preventDefault()
            })

            trigger.addEventListener('mouseenter', () => {
                setTimeoutConst = setTimeout(function () {
                    menuAnimation().then((module) => {
                        trigger.classList.add('active')
                        module.reveal(subMenu)
                    })
                }, delay)
            })

            menu.addEventListener('mouseleave', () => {
                clearTimeout(setTimeoutConst)

                setTimeoutConst = setTimeout(function () {
                    menuAnimation().then((module) => {
                        trigger.classList.remove('active')
                        module.unReveal(subMenu)
                    })
                }, delay)
            })
        }
    }
}

const pageTransition = () => {
    const links = document.querySelectorAll('a[href]'),
        transition = () => import('./animations/_page-transition')

    for (const link of links) {
        const
            parent = link.parentNode,
            url = link.href,
            isSpecialUrl = (url) => {
                return parent.classList.contains('menu-item-has-children') || url === window.location.href + '#' || link.getAttribute('target') || url === '#' || url.substr(0, 4) === 'tel:' || url.substr(0, 7) === 'mailto:'
            }

        if (!isSpecialUrl(url)) {
            link.addEventListener('click', (event) => {
                event.preventDefault()

                transition().then((module) => {
                    module.fadeOut(url)
                })
            })
        }
    }
}

const mobileMenu = () => {
    const
        toggles = document.querySelectorAll('.toggle-mobile-menu'),
        toggleAnimation = () => import('./animations/_toggle-mobile-menu')

    let isOpen = false

    for (const toggle of toggles) {
        toggle.addEventListener('click', () => {
            toggleAnimation().then((module) => {
                module.toggleMenu(isOpen)

                isOpen = !isOpen
            })
        })
    }
}

const mobileSubMenu = () => {
    const
        mobileSubMenus = document.querySelectorAll('.menu-item-has-children'),
        mobileMenuAnimation = () => import('./animations/_mobile-sub-menu')

    if (mobileSubMenus.length > 0) {
        for (const mobileMenu of mobileSubMenus) {
            const
                activator = mobileMenu.querySelector('* > a'),
                menu = mobileMenu.querySelector('.sub-menu')

            let isOpen = false

            activator.addEventListener('click', (event) => {
                event.preventDefault()

                mobileMenuAnimation().then((module) => {
                    mobileMenu.classList.toggle('menu-item-has-children--open')

                    if (!isOpen) {
                        module.openMenu(menu)
                    } else {
                        module.closeMenu(menu)
                    }

                    isOpen = !isOpen
                })
            })
        }
    }
}

export {revealElementsOnScroll, scrollTo, changeSiteGradient, headerSubMenu, pageTransition, mobileMenu, mobileSubMenu}