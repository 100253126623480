'use strict'

// postcss stylesheets
import './../styles/_tailwind.pcss'
import './../styles/main.pcss'
import {changeSiteGradient} from './_animations'

const
    animations = () => import('./_animations'),
    global = () => import('./_global'),
    latestPostsSlider = () => import('./sliders/_latest-posts'),
    lazyLoading = () => import('./_lazy-load'),
    pageEntry = () => import('./animations/_page-transition'),
    resizeMobileLogo = () => import('./animations/_resize-logo-mobile')

// Event listeners

document.addEventListener('DOMContentLoaded', () => {
    global().then((module) => {
        module.pageLoadedChecker()
    })

    latestPostsSlider().then((module) => {
        module._latestPosts()
    })
});

document.addEventListener('everyThingIsLoaded', () => {
    changeSiteGradient()

    lazyLoading().then((lazyload) => {
        lazyload.init()
    })

    pageEntry().then((module) => {
        module.fadeIn()
    })

    animations().then((module) => {
        module.revealElementsOnScroll()
        module.headerSubMenu()
        module.pageTransition()
        module.mobileSubMenu()
        module.mobileMenu()
    })

    resizeMobileLogo().then((module) => {
        module.resizeLogo()
        module.fixedDesktopHeader()
    })
})

window.addEventListener('pagehide', () => {
    pageEntry().then((module) => {
        module.fadeIn()
    })
});